/* 
  3. Add keywords
  4. Add description
*/

import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Coeliac Disease";

// Download link - http://www.fbrennangastro.com.au/downloads/gesacoeliacdisease.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "Gluten", "Food absorption", "nutrient absorption"]}
			type="article"
			description="Coeliac disease and gluten - syptoms in affected people, why it happens"
		/>
		<H1>{title}</H1>
		<Par>
			Coeliac disease affects approximately 1 in 100 Australians. In coeliac disease the immune system reacts abnormally
			to gluten. People affected do not absorb food and nutrients well.
		</Par>
		<Par>
			Coeliac disease is a condition in which the lining of the small intestine becomes damaged when it is exposed to
			even small amounts of gluten, a protein found in wheat, barley, rye and possibly oats.
		</Par>
		<Par>It is usually characterised by symptoms such as diarrhoea, malabsorption and weight loss.</Par>
		<Par>
			Untreated, coeliac disease can lead to chronic poor health, osteoporosis, infertility, miscarriage, depression and
			dental enamel defects.
		</Par>
		<Par>
			A gluten-free diet* allows the condition to be effectively managed, improving symptoms and restoring health. It
			should be undertaken with medical supervision.
		</Par>
	</Blog>
);
